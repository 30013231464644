import React, { useContext } from "react";
import { Context } from "../../context";
import MenuFR from "./fr";
import MenuDE from "./de";
import MenuIT from "./it";
import MenuEN from "./en";

export default function Menu() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <MenuDE />;
    case "it":
      return <MenuIT />;
    case "en":
      return <MenuEN />;
    default:
      return <MenuFR />;
  }
}
