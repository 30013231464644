import React from "react";
import "./../../styles/components/Bens.css";

export default function BensDE() {
  return (
    <div className="bens">
      <div className="bens-container">
        <h2 className="bens-title">Ben S. Digital Change</h2>
        <p className="bens-text">
          Ben S. Digital Change ist ein Finanzintermediär, der sich auf den
          Wechsel zwischen Schweizer Franken und Euro spezialisiert hat.
        </p>
        <p className="bens-text">
          Es ist ein Warenzeichen von FANIRO SARL, einem anerkannten Unternehmen
          für <b>57 Jahre</b> in Genf.
        </p>
        <p className="bens-text">
          Es ist ein <b>Mitglied von ARIF</b>, einer von anerkannten
          Selbstregulierungsorganisation FINMA.
        </p>
      </div>
      <div className="bens-container has-circle">
        <div className="bens-item">
          <img
            src="/img/ben-s-01.webp"
            alt="Bens 01"
            className="bens-img"
            loading="lazy"
            width={175}
            height={145}
          />
          <h3 className="bens-title">Erstellen Sie Ihr Kundenkonto</h3>
          <p className="bens-desc">
            Die Registrierung ist kostenlos, sicher und ohne
            Registrierungsgebühren
          </p>
        </div>
        <div className="bens-item">
          <img
            src="/img/ben-s-02.webp"
            alt="Bens 02"
            className="bens-img"
            loading="lazy"
            width={173}
            height={141}
          />
          <h3 className="bens-title">Überweisen Sie Ihr Geld</h3>
          <p className="bens-desc">
            Von Ihrem E-Banking aus senden Sie die Gelder ohne Wechselgeld
            Transaktions Gebühren
          </p>
        </div>
        <div className="bens-item">
          <img
            src="/img/ben-s-03.webp"
            alt="Bens 03"
            className="bens-img"
            loading="lazy"
            width={175}
            height={145}
          />
          <h3 className="bens-title">
            Umwandlung Ihrer Gelder in +/- 24 Stunden
          </h3>
          <p className="bens-desc">
            Ihr Geld wird umgerechnet und kommt in der Regel innerhalb von 24
            Stunden auf Ihrem Zielkonto an.
          </p>
        </div>
      </div>
    </div>
  );
}
