import React from "react";
import "./../../styles/components/Offer.css";


export default function OfferIT() {
  return (
    <div className="offer">
      <div className="bens-container offer-container">
        <div className="offer-media" data-tag="offer-media">
          <img
            src="https://bens-digital-change.com/images/v2/rewards/woman.jpg"
            alt="Banner Icon"
            width={64}
            height={64}
            data-tag="offer-media-img"
          />
        </div>
        <div className="offer-content" data-tag="offer-content">
          <p>
            Grâce à Ben S. Digital Rewards, Manon bénéficie de <b>285 CHF</b> chaque
            année, en plus des meilleurs taux !
          </p>
        </div>
        <div className="offer-action" data-tag="offer-action">
          <a
            href="https://bens-digital-change.com/rewards"
            target="_blank"
            rel="noopener noreferrer"
            data-tag="offer-action-link"
          >
            Découvrir Ben S. Digital Rewards
          </a>
        </div>
      </div>
    </div>
  );
}
