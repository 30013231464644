import React from "react";
import "./../../styles/components/Solutions.css";
import CurrentTheme from "./../CurrentTheme";
import config from "../../config";

export default function SolutionsEN() {
  return (
    <div className="solutions">
      <div className="bens-container">
        <div className="solutions-top">
          <div className="box-col">
            <div className="media-group">
              <a
                className="media-item"
                href="https://bens-digital-change.com/employeur#etape1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>1. Register your employer in your customer area</h4>
                <img
                  src="/img/sol-01_emp.png"
                  alt="Sol 01"
                  loading="lazy"
                  width={340}
                  height={341}
                />
              </a>

              <a
                className="media-item"
                href="https://bens-digital-change.com/employeur#etape2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>2. Employer validation by our team</h4>
                <img
                  src="/img/sol-01_emp.png"
                  alt="Sol 02"
                  loading="lazy"
                  width={340}
                  height={351}
                />
              </a>
            </div>

            <div className="media-group">
              <a
                className="media-item"
                href="https://bens-digital-change.com/employeur#etape3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>3. Download the document to give to your employer</h4>
                <img
                  src="/img/sol-01_emp.png"
                  alt="Sol 03"
                  loading="lazy"
                  width={358}
                  height={351}
                />
              </a>

              <a
                className="media-item"
                href="https://bens-digital-change.com/employeur#etape4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>
                  4. Reception of your funds (in EUR) within 24 hours (working
                  day)
                </h4>
                <img
                  src="/img/sol-01_emp.png"
                  alt="Sol 04"
                  loading="lazy"
                  width={351}
                  height={363}
                />
              </a>
            </div>
          </div>
          <div className="box-col solutions-top-right">
            <h2>
              <b>New Service</b>
            </h2>
            <h2>Start receiving your salary directly from your employer</h2>
            <p className="box-long-desc">
              With our online exchange service, you are now possible to receive
              your salary on your account in euros in a simple, fast and secure
              way, without having to open a bank account in Switzerland. That's
              what <b>save fees</b> related to account maintenance, which can
              turn out to be quite expensive.
            </p>
            <a
              href="https://bens-digital-change.swiss/register"
              target="_blank"
              rel="noopener noreferrer"
            >
              Free and non-binding registration
            </a>
            <a
              href="https://calendly.com/bens_digital/demande-d-assistance"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact our advisors
            </a>
          </div>
        </div>

        <div className="section-08-box solutions-bottom">
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/get-mobile-back.webp"
              alt="Get Mobile"
              className="box-img"
              loading="lazy"
              width={733}
              height={671}
            />
          </div>
          <div className="box-col">
            <h2 className="box-title">Our solution is available on mobile</h2>
            <img
              src="/img/bens-title-dark.svg"
              alt="Bens"
              className="box-desc-img"
              loading="lazy"
              width={399}
              height={51}
            />
            <p className="box-long-desc">
              Carry out all your foreign exchange transactions on our
              application available on Android and IOS. Do you want to change
              your currency at a specific time?
            </p>
            <p className="box-long-desc">
              Our application offers you the possibility to create an alert to
              notify you when the exchange rate reaches the one you you desire.
            </p>
            <a
              href="https://bens-digital-change.ch/download-mobile-app"
              className="box-store"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://bens-digital-change.com/images/v2/bens-stores.webp"
                alt="Bens Store"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        className="solutions-back"
        style={{
          background:
            "linear-gradient(180deg, " +
            CurrentTheme(config.theme)["color"][0] +
            " 0%, " +
            CurrentTheme(config.theme)["color"][1] +
            " 100%)",
        }}
      ></div>
    </div>
  );
}
