import React, { useContext } from "react";
import { Context } from "../../context";
import HeroFR from "./fr";
import HeroDE from "./de";
import HeroIT from "./it";
import HeroEN from "./en";

export default function Hero() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <HeroDE />;

    case "it":
      return <HeroIT />;

    case "en":
      return <HeroEN />;

    default:
      return <HeroFR />;
  }
}
