import React from "react";
import "./../../styles/components/Bens.css";

export default function BensFR() {
  return (
    <div className="bens">
      <div className="bens-container">
        <h2 className="bens-title">Ben S. Digital Change</h2>
        <p className="bens-text">
          Ben S. Digital Change est un intermédiaire financier spécialisé dans
          le change Franc Suisses – Euros.
        </p>
        <p className="bens-text">
          C’est une marque commerciale de FANIRO SARL, entreprise reconnue
          depuis <b>57 ans</b> à Genève.
        </p>
        <p className="bens-text">
          Elle est <b>membre de l’ARIF</b>, organisme d’autorégulation agréé par
          la FINMA.
        </p>
      </div>
      <div className="bens-container has-circle">
        <div className="bens-item">
          <img
            src="/img/ben-s-01.webp"
            alt="Bens 01"
            className="bens-img"
            loading="lazy"
            width={175}
            height={145}
          />
          <h3 className="bens-title">Créer votre compte client</h3>
          <p className="bens-desc">
            L’inscription est gratuite, sécurisée et sans aucuns frais
            d’inscription
          </p>
        </div>
        <div className="bens-item">
          <img
            src="/img/ben-s-02.webp"
            alt="Bens 02"
            className="bens-img"
            loading="lazy"
            width={173}
            height={141}
          />
          <h3 className="bens-title">Transférez vos fonds</h3>
          <p className="bens-desc">
            Depuis votre E-banking vous envoyez les fonds à changer sans aucun
            frais de transaction
          </p>
        </div>
        <div className="bens-item">
          <img
            src="/img/ben-s-03.webp"
            alt="Bens 03"
            className="bens-img"
            loading="lazy"
            width={175}
            height={145}
          />
          <h3 className="bens-title"> Conversion de vos fonds en +/- 24 h</h3>
          <p className="bens-desc">
            Vos fonds seront convertis et arriveront sur votre compte de
            destination généralement sous 24 h.
          </p>
        </div>
      </div>
    </div>
  );
}
