import React, { useContext } from "react";
import { Context } from "./../../context";
import CalculatorFR from "./fr";
import CalculatorDE from "./de";
import CalculatorIT from "./it";
import CalculatorEN from "./en";

export default function Calculator() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <CalculatorDE />;
    case "it":
      return <CalculatorIT />;
    case "en":
      return <CalculatorEN />;
    default:
      return <CalculatorFR />;
  }
}
