import React from "react";
import "./../../styles/components/Commitment.css";

export default function CommitmentEN() {
  return (
    <div className="commitment">
      <div className="bens-container">
        <h2 className="section-11-title">Engagement Ben S. Digital Change</h2>
        <div className="section-11-box">
          <div className="box-col">
            <img
              src="/img/com-03.webp"
              alt="Security"
              className="box-img"
              loading="lazy"
              width={129}
              height={166}
            />
            <h3 className="box-title">Security</h3>
            <p className="box-desc">
              Our company is a member of ARIF, a self-regulatory organization
              approved by FINMA. Carry out your operations in complete safety.
              Our foreign exchange service is monitored to meet the directives
              of the Swiss Confederation
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-02.webp"
              alt="Proximity"
              className="box-img"
              loading="lazy"
              width={172}
              height={187}
            />
            <h3 className="box-title">Proximity</h3>
            <p className="box-desc">
              Physical exchange offices at your disposal for you accompany you
              from your registration to the completion of your CHF-EUR exchange
              transactions
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-05.webp"
              alt="Intuitiveness"
              className="box-img"
              loading="lazy"
              width={158}
              height={183}
            />
            <h3 className="box-title">Intuitiveness</h3>
            <p className="box-desc">
              Our platform is easy to use and accessible to everyone. With Our
              App, change your currencies at the time you want, from the place
              you want
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-01.webp"
              alt="Speed"
              className="box-img"
              loading="lazy"
              width={169}
              height={175}
            />
            <h3 className="box-title">Speed</h3>
            <p className="box-desc">
              You will receive your funds converted into EUR or CHF generally in
              24 hours (working day)
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-04.webp"
              alt="Economy"
              className="box-img"
              loading="lazy"
              width={153}
              height={161}
            />
            <h3 className="box-title">Economy</h3>
            <p className="box-desc">
              Thanks to a more advantageous CHF-EUR exchange rate, and no fees
              hidden, you save on each exchange transaction
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
