import React from "react";
import "./../../styles/components/Commitment.css";

export default function CommitmentDE() {
  return (
    <div className="commitment">
      <div className="bens-container">
        <h2 className="section-11-title">Engagement Ben S. Digital Change</h2>
        <div className="section-11-box">
          <div className="box-col">
            <img
              src="/img/com-03.webp"
              alt="Sicherheit"
              className="box-img"
              loading="lazy"
              width={129}
              height={166}
            />
            <h3 className="box-title">Sicherheit</h3>
            <p className="box-desc">
              Unser Unternehmen ist Mitglied der ARIF, einer
              Selbstregulierungsorganisation von der FINMA genehmigt. Führen Sie
              Ihre Arbeiten in absoluter Sicherheit durch. Unser Devisendienst
              wird überwacht, um die zu erfüllen Richtlinien der Schweizerischen
              Eidgenossenschaft
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-02.webp"
              alt="Nähe"
              className="box-img"
              loading="lazy"
              width={172}
              height={187}
            />
            <h3 className="box-title">Nähe</h3>
            <p className="box-desc">
              Physische Wechselstuben stehen Ihnen zur Verfügung begleiten Sie
              von Ihrer Anmeldung bis zum Abschluss Ihres Wechselgeschäfte
              CHF-EUR
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-05.webp"
              alt="Intuition"
              className="box-img"
              loading="lazy"
              width={158}
              height={183}
            />
            <h3 className="box-title">Intuition</h3>
            <p className="box-desc">
              Unsere Plattform ist einfach zu bedienen und für jeden zugänglich.
              Mit unserer App ändern Sie Ihre Währungen zum gewünschten
              Zeitpunkt und von jedem Ort aus
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-01.webp"
              alt="Geschwindigkeit"
              className="box-img"
              loading="lazy"
              width={169}
              height={175}
            />
            <h3 className="box-title">Geschwindigkeit</h3>
            <p className="box-desc">
              Sie erhalten Ihr Geld in der Regel in EUR oder CHF umgerechnet 24
              Stunden (Werktag)
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-04.webp"
              alt="Wirtschaft"
              className="box-img"
              loading="lazy"
              width={153}
              height={161}
            />
            <h3 className="box-title">Wirtschaft</h3>
            <p className="box-desc">
              Dank günstigerem CHF-EUR-Wechselkurs und ohne Gebühren versteckt,
              sparen Sie bei jedem Umtauschvorgang
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
