import React, { createContext, useEffect, useState } from "react";

export const Context = createContext(null);

const initialState = {
  menu: false,
  locale: "fr",
};

export const ContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const storageState = localStorage.getItem("employeur_state");

    if (storageState !== null) {
      setState({
        ...state,
        menu: storageState.menu,
        locale: storageState.locale,
      });
    } else {
      setState({
        ...state,
        menu: initialState.menu,
        locale: initialState.locale,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const lang = navigator.language;
    switch (lang) {
      case "de":
        setState({ ...state, locale: "de" });
        break;
      case "it":
        setState({ ...state, locale: "it" });
        break;
      case "en":
        setState({ ...state, locale: "en" });
        break;
      default:
        setState({ ...state, locale: "fr" });
        break;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    JSON.stringify(state) === JSON.stringify(initialState)
      ? localStorage.setItem("employeur_state", JSON.stringify(state))
      : localStorage.setItem("employeur_state", JSON.stringify(initialState));
  }, [state]);

  const value = { state, setState };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
