import React, { useContext } from "react";
import { Context } from "./../../context";
import BensFR from "./fr";
import BensDE from "./de";
import BensIT from "./it";
import BensEN from "./en";

export default function Bens() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <BensDE />;
    case "it":
      return <BensIT />;
    case "en":
      return <BensEN />;
    default:
      return <BensFR />;
  }
}
