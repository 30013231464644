import React, { useContext } from "react";
import { Context } from "./../../context";

import AdvantagesFR from "./fr";
import AdvantagesDE from "./de";
import AdvantagesIT from "./it";
import AdvantagesEN from "./en";

export default function Advantages() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <AdvantagesDE />;
    case "it":
      return <AdvantagesIT />;
    case "en":
      return <AdvantagesEN />;
    default:
      return <AdvantagesFR />;
  }
}
