import React from "react";
import "./../../styles/components/Advantages.css";
import CurrentTheme from "./../CurrentTheme";
import config from "../../config";

export default function AdvantagesEN() {
  return (
    <div
      className="advantages"
      style={{
        background:
          "linear-gradient(0deg, " +
          CurrentTheme(config.theme)["color"][0] +
          " 0%, " +
          CurrentTheme(config.theme)["color"][1] +
          " 50%)",
      }}
    >
      <div className="bens-container">
        <h2 className="box-title">
          The advantages of changing currencies online
        </h2>
        <div className="box-container">
          <div className="box-item">
            <img
              src="/img/adv-01.webp"
              alt="Adv 01"
              loading="lazy"
              width={176}
              height={158}
            />
            <h3>Flexibility</h3>
            <p>
              No specific location! No hours! To change currency online, it is
              possible to do it no matter where we are situated. All you need is
              an internet connection, a smartphone, and you're done.
            </p>
          </div>

          <div className="box-item">
            <img
              src="/img/adv-02.webp"
              alt="Adv 02"
              loading="lazy"
              width={173}
              height={184}
            />
            <h3>Automate your transactions</h3>
            <p>
              Regular transactions? Need to convert your salary? Carefree. Place
              your conversion order and automate it. Each month, upon receipt of
              your money, we take care of the exchange of your salary and we
              transfer it to you immediately to the destination account.
            </p>
          </div>

          <div className="box-item">
            <img
              src="/img/adv-03.webp"
              alt="Adv 03"
              loading="lazy"
              width={158}
              height={177}
            />
            <h3>More security</h3>
            <p>
              With digital exchange, there is no longer any risk (theft, loss).
              Insurance coverage with a major insurance company Switzerland is
              also contracted to prevent any risk possible.
            </p>
          </div>

          <div className="box-item">
            <img
              src="/img/adv-04.webp"
              alt="Adv 04"
              loading="lazy"
              width={153}
              height={161}
            />
            <h3>Savings</h3>
            <p>Saving money is essential for you?</p>
            <p>
              With online exchange, enjoy a slightly more advantageous rate
              compared to physical exchange offices.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
