import React, { useContext } from "react";
import { Context } from "../../context";
import FooterFR from "./fr";
import FooterDE from "./de";
import FooterIT from "./it";
import FooterEN from "./en";

export default function Footer() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <FooterDE />;
    case "it":
      return <FooterIT />;
    case "en":
      return <FooterEN />;
    default:
      return <FooterFR />;
  }
}
