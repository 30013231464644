import React from "react";
import "./../../styles/components/Bens.css";

export default function BensIT() {
  return (
    <div className="bens">
      <div className="bens-container">
        <h2 className="bens-title">Ben S. Digital Change</h2>
        <p className="bens-text">
          Ben S. Digital Change è un intermediario finanziario specializzato in
          Cambio franco svizzero – euro.
        </p>
        <p className="bens-text">
          È un marchio di FANIRO SARL, azienda riconosciuta per <b>57 anni</b> a
          Ginevra.
        </p>
        <p className="bens-text">
          È <b>membro dell'ARIF</b>, un organismo di autoregolamentazione
          approvato da FINMA.
        </p>
      </div>
      <div className="bens-container has-circle">
        <div className="bens-item">
          <img
            src="/img/ben-s-01.webp"
            alt="Bens 01"
            className="bens-img"
            loading="lazy"
            width={175}
            height={145}
          />
          <h3 className="bens-title">Crea il tuo account cliente</h3>
          <p className="bens-desc">
            La registrazione è gratuita, sicura e senza costi di registrazione
          </p>
        </div>
        <div className="bens-item">
          <img
            src="/img/ben-s-02.webp"
            alt="Bens 02"
            className="bens-img"
            loading="lazy"
            width={173}
            height={141}
          />
          <h3 className="bens-title">Trasferisci i tuoi fondi</h3>
          <p className="bens-desc">
            Dal tuo E-banking invii i fondi da cambiare senza alcuno commissioni
            di transazione
          </p>
        </div>
        <div className="bens-item">
          <img
            src="/img/ben-s-03.webp"
            alt="Bens 03"
            className="bens-img"
            loading="lazy"
            width={175}
            height={145}
          />
          <h3 className="bens-title">
            Conversione dei tuoi fondi in +/- 24 ore
          </h3>
          <p className="bens-desc">
            I tuoi fondi verranno convertiti e arriveranno sul tuo conto da
            destinazione generalmente entro 24 ore.
          </p>
        </div>
      </div>
    </div>
  );
}
