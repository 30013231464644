import React from "react";
import "./../../styles/components/Bens.css";

export default function BensEN() {
  return (
    <div className="bens">
      <div className="bens-container">
        <h2 className="bens-title">Ben S. Digital Change</h2>
        <p className="bens-text">
          Ben S. Digital Change is a financial intermediary specializing in
          Swiss Franc – Euro exchange.
        </p>
        <p className="bens-text">
          It is a trademark of FANIRO SARL, a recognized company for{" "}
          <b>57 years</b> in Geneva.
        </p>
        <p className="bens-text">
          It is a <b>member of ARIF</b>, a self-regulatory body approved by
          FINMA.
        </p>
      </div>
      <div className="bens-container has-circle">
        <div className="bens-item">
          <img
            src="/img/ben-s-01.webp"
            alt="Bens 01"
            className="bens-img"
            loading="lazy"
            width={175}
            height={145}
          />
          <h3 className="bens-title">Create your customer account</h3>
          <p className="bens-desc">
            Registration is free, secure and without any registration fees
          </p>
        </div>
        <div className="bens-item">
          <img
            src="/img/ben-s-02.webp"
            alt="Bens 02"
            className="bens-img"
            loading="lazy"
            width={173}
            height={141}
          />
          <h3 className="bens-title">Transfer your funds</h3>
          <p className="bens-desc">
            From your E-banking you send the funds to change without any
            transaction fees
          </p>
        </div>
        <div className="bens-item">
          <img
            src="/img/ben-s-03.webp"
            alt="Bens 03"
            className="bens-img"
            loading="lazy"
            width={175}
            height={145}
          />
          <h3 className="bens-title">Conversion of your funds in +/- 24h</h3>
          <p className="bens-desc">
            Your funds will be converted and arrive in your account from
            destination generally within 24 hours.
          </p>
        </div>
      </div>
    </div>
  );
}
