import React from "react";
import "./../../styles/components/Solutions.css";
import CurrentTheme from "./../CurrentTheme";
import config from "../../config";

export default function SolutionsDE() {
  return (
    <div className="solutions">
      <div className="bens-container">
        <div className="solutions-top">
          <div className="box-col">
            <div className="media-group">
              <a
                className="media-item"
                href="https://bens-digital-change.com/employeur#etape1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>
                  1. Registrieren Sie Ihren Arbeitgeber in Ihrem Kundenbereich
                </h4>
                <img
                  src="/img/sol-01_emp.png"
                  alt="Sol 01"
                  loading="lazy"
                  width={340}
                  height={341}
                />
              </a>

              <a
                className="media-item"
                href="https://bens-digital-change.com/employeur#etape2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>2. Arbeitgeber-Validierung durch unser Team</h4>
                <img
                  src="/img/sol-01_emp.png"
                  alt="Sol 02"
                  loading="lazy"
                  width={340}
                  height={351}
                />
              </a>
            </div>

            <div className="media-group">
              <a
                className="media-item"
                href="https://bens-digital-change.com/employeur#etape3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>
                  3. Laden Sie das Dokument herunter, um es Ihrem Arbeitgeber zu
                  geben
                </h4>
                <img
                  src="/img/sol-01_emp.png"
                  alt="Sol 03"
                  loading="lazy"
                  width={358}
                  height={351}
                />
              </a>

              <a
                className="media-item"
                href="https://bens-digital-change.com/employeur#etape4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>
                  4. Erhalt Ihres Geldes (in EUR) innerhalb von 24 Stunden
                  (Werktag)
                </h4>
                <img
                  src="/img/sol-01_emp.png"
                  alt="Sol 04"
                  loading="lazy"
                  width={351}
                  height={363}
                />
              </a>
            </div>
          </div>
          <div className="box-col solutions-top-right">
            <h2>
              <b>Neuer Dienst</b>
            </h2>
            <h2>Erhalten Sie Ihr Gehalt direkt von Ihrem Arbeitgeber</h2>
            <p className="box-long-desc">
              Mit unserem Online-Umtauschservice sind Sie es jetzt möglich, Ihr
              Gehalt in Euro auf Ihrem Konto zu erhalten auf einfache, schnelle
              und sichere Weise, ohne eine öffnen zu müssen Bankkonto in der
              Schweiz. Das ist, was <b>Kosten sparen</b> im Zusammenhang mit der
              Kontoführung, die ziemlich teuer sein kann.
            </p>
            <a
              href="https://bens-digital-change.swiss/register"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kostenlose und unverbindliche Anmeldung
            </a>
            <a
              href="https://calendly.com/bens_digital/demande-d-assistance"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kontaktieren Sie unsere Berater
            </a>
          </div>
        </div>

        <div className="section-08-box solutions-bottom">
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/get-mobile-back.webp"
              alt="Get Mobile"
              className="box-img"
              loading="lazy"
              width={733}
              height={671}
            />
          </div>
          <div className="box-col">
            <h2 className="box-title">Unsere Lösung ist mobil verfügbar</h2>
            <img
              src="/img/bens-title-dark.svg"
              alt="Bens"
              className="box-desc-img"
              loading="lazy"
              width={399}
              height={51}
            />
            <p className="box-long-desc">
              Führen Sie alle Ihre Devisentransaktionen mit unserer Anwendung
              durch, die für Android und IOS verfügbar ist. Möchten Sie Ihre
              Währung zu einem bestimmten Zeitpunkt ändern?
            </p>
            <p className="box-long-desc">
              Unsere Anwendung bietet Ihnen die Möglichkeit, eine
              Benachrichtigung zu erstellen um Sie zu benachrichtigen, wenn der
              Wechselkurs Ihren erreicht Sie wünschen.
            </p>
            <a
              href="https://bens-digital-change.ch/download-mobile-app"
              className="box-store"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://bens-digital-change.com/images/v2/bens-stores.webp"
                alt="Bens Store"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        className="solutions-back"
        style={{
          background:
            "linear-gradient(180deg, " +
            CurrentTheme(config.theme)["color"][0] +
            " 0%, " +
            CurrentTheme(config.theme)["color"][1] +
            " 100%)",
        }}
      ></div>
    </div>
  );
}
