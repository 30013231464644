import React, { useContext } from "react";
import { Context } from "../../context";
import TrustpilotWidgetFR from "./fr";
import TrustpilotWidgetDE from "./de";
import TrustpilotWidgetIT from "./it";
import TrustpilotWidgetEN from "./en";

export default function TrustpilotWidget() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <TrustpilotWidgetDE />;
    case "it":
      return <TrustpilotWidgetIT />;
    case "en":
      return <TrustpilotWidgetEN />;
    default:
      return <TrustpilotWidgetFR />;
  }
}
