import React from "react";
import "./../../styles/utils/Footer.css";
import TrustpilotWidget from "./../../components/TrustpilotWidget";
import TrustpilotCarousel from "./../../components/TrustpilotCarousel";

export default function FooterFR() {
  return (
    <footer id="bens-footer">
      <div className="footer-top">
        <div className="bens-container">
          <TrustpilotCarousel></TrustpilotCarousel>

          <div className="footer-col">
            <div className="section-map-info">
              <h3 className="bens-section-04-subtitle is-third">
                Notre Service Client est disponible :
              </h3>
              <div className="bens-section-04-list">
                <div className="bens-section-04-item">
                  Du Lundi au Vendredi de 09h00 à 17h00
                </div>
                <div className="bens-section-04-item">
                  <img
                    src="https://bens-digital-change.com/images/v2/icon-custom-phone.svg"
                    alt="Phone"
                    width={24}
                    height={24}
                    loading="lazy"
                  />
                  <a
                    className="item-phone"
                    href="tel:+41223292828"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +41 22 329 28 28
                  </a>
                </div>
                <div className="bens-section-04-item">
                  <img
                    src="https://bens-digital-change.com/images/v2/icon-custom-envelope.svg"
                    alt="Mail"
                    width={24}
                    height={24}
                    loading="lazy"
                  />
                  <a
                    className="item-mail"
                    href="mailto:contact@bens-digital-change.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    contact@bens-digital-change.com
                  </a>
                </div>
              </div>
            </div>

            <div className="section-map">
              <a
                href="https://goo.gl/maps/3xUoctTx15zxqfcE9"
                target="_blank"
                rel="noopener noreferrer"
                className="map-item"
              >
                <div className="map-caption is-first">
                  <h4>CEVA / Eaux-Vives</h4>
                  <span>Rte de Chêne 5 1207 Geneve</span>
                  <span>
                    Lun-Ven <b>9h/17h</b>
                  </span>
                </div>
                <img
                  className="map-media"
                  src="https://bens-digital-change.com/images/v2/map-item-Eaux-Vives.webp"
                  alt="Map"
                  loading="lazy"
                />
              </a>
              <a
                href="https://goo.gl/maps/XvAKJfiyxH55j3Lx8"
                target="_blank"
                rel="noopener noreferrer"
                className="map-item"
              >
                <div className="map-caption is-first">
                  <h4>Plainpalais</h4>
                  <span>Blvd du Pont d’Arves, 28 1205 Genève</span>
                  <span>
                    Lun-Ven <b>9h/17h</b>
                  </span>
                </div>
                <img
                  className="map-media"
                  src="https://bens-digital-change.com/images/v2/map-item-Plainpalais.webp"
                  alt="Map"
                  loading="lazy"
                />
              </a>
            </div>

            <div className="section-map">
              <a
                href="https://goo.gl/maps/nDsMZZ9aVbVJ13vq7"
                target="_blank"
                rel="noopener noreferrer"
                className="map-item"
              >
                <div className="map-caption is-second">
                  <h4>Perly</h4>
                  <span>274 Rte de St-Julien 1258 Perly</span>
                  <span>
                    Lun-Sam <b>7h/20h</b>
                  </span>
                  <span>
                    Dim <b>8h/20h</b>
                  </span>
                </div>
                <img
                  className="map-media"
                  src="https://bens-digital-change.com/images/v2/map-item-Perly.webp"
                  alt="Map"
                  loading="lazy"
                />
              </a>
              <a
                href="https://goo.gl/maps/rZWFUK7oC75izk5y9"
                target="_blank"
                rel="noopener noreferrer"
                className="map-item"
              >
                <div className="map-caption is-second">
                  <h4>Thônex</h4>
                  <span>58 rt d’Ambilly 1226 Thônex</span>
                  <span>
                    Lun-Sam <b>7h/20h</b>
                  </span>
                  <span>
                    Dim <b>8h/20h</b>
                  </span>
                </div>
                <img
                  className="map-media"
                  src="https://bens-digital-change.com/images/v2/map-item-Thonex.webp"
                  alt="Map"
                  loading="lazy"
                />
              </a>
              <a
                href="https://goo.gl/maps/wQSazRGaFb26EfjJA"
                target="_blank"
                rel="noopener noreferrer"
                className="map-item"
              >
                <div className="map-caption is-second">
                  <h4>Balexert ( Aéroport )</h4>
                  <span>Avenue Louis Casaï, 34 1216 Cointrin</span>
                  <span>
                    Lun-Sam <b>6h/20h</b>
                  </span>
                  <span>
                    Dim <b>8h/16h</b>
                  </span>
                </div>
                <img
                  className="map-media"
                  src="https://bens-digital-change.com/images/v2/map-item-Balexert.webp"
                  alt="Map"
                  loading="lazy"
                />
              </a>
            </div>
          </div>

          <div className="footer-col has-links">
            <div className="footer-widget is-row is-top has-social">
              <a href="/" className="footer-social-icon">
                <img
                  src="https://bens-digital-change.com/images/icons/swiss_made.svg"
                  alt="Swiss Made"
                  width={48}
                  height={48}
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.facebook.com/BenS.Change.Shop"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <img
                  src="https://bens-digital-change.com/images/icons/facebook.svg"
                  alt="Facebook"
                  width={48}
                  height={48}
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.instagram.com/bens.solutions.de.change"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <img
                  src="https://bens-digital-change.com/images/icons/instagram.svg"
                  alt="Instagram"
                  width={48}
                  height={48}
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/ben-s-solutions-de-change"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <img
                  src="https://bens-digital-change.com/images/icons/linkedin.svg"
                  alt="Linkedin"
                  width={48}
                  height={48}
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UC3S8yVGmtgOStQzQrZh8fIg"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <img
                  src="https://bens-digital-change.com/images/icons/youtube.svg"
                  alt="Youtube"
                  width={48}
                  height={48}
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.tiktok.com/@bens.solutions.de.change"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <img
                  src="https://bens-digital-change.com/images/icons/tiktok.svg"
                  alt="TikTok"
                  width={48}
                  height={48}
                  loading="lazy"
                />
              </a>
            </div>
            <div className="footer-widget is-row is-top has-store">
              <a
                href="https://apps.apple.com/us/app/keynote/id1587182131"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-ios-store"
              >
                <img
                  src="https://bens-digital-change.com/images/v2/ios-store.svg"
                  alt="Bens on IOS Store"
                  width={120}
                  height={40}
                  loading="lazy"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=ch.ischange.digital_change_app.bens"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-android-store"
              >
                <img
                  src="https://bens-digital-change.com/images/v2/android-store.svg"
                  alt="Bens on Andorid Store"
                  width={135}
                  height={40}
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="bens-container">
          <div className="footer-bottom-left">
            <TrustpilotWidget></TrustpilotWidget>
          </div>

          <div className="footer-bottom-right">
            <span data-tag="footer-servette-text">Partenaire de :</span>
            <img
              className="footer-bottom-image"
              data-tag="footer-lions"
              src="https://bens-digital-change.com/images/v2/footer-logo-servette-rugby-club.webp"
              alt="Servette Rugby Club"
              width={600}
              height={668}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </footer>
  );
}
