import React from "react";
import "./../../styles/components/TrustpilotCarousel.css";

export default function TrustpilotCarouselIT() {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div id="bens-section-06">
      {/* TrustBox widget - Carousel */}
      <div
        ref={ref}
        className="trustpilot-widget is-carousel"
        data-locale="it"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="624471da9077911b7629b8db"
        data-style-height="140px"
        data-style-width="100%"
        data-theme="light"
        data-stars={5}
        data-review-languages="*"
      >
        <a
          href="https://it.trustpilot.com/review/bens-digital-change.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
        {/* End TrustBox widget */}
      </div>
    </div>
  );
}
