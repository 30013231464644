import React, { useContext } from "react";
import { Context } from "./../../context";
import CommitmentFR from "./fr";
import CommitmentDE from "./de";
import CommitmentIT from "./it";
import CommitmentEN from "./en";

export default function Commitment() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <CommitmentDE />;
    case "it":
      return <CommitmentIT />;
    case "en":
      return <CommitmentEN />;
    default:
      return <CommitmentFR />;
  }
}
