import React from "react";
import "./../../styles/components/Commitment.css";

export default function CommitmentFR() {
  return (
    <div className="commitment">
      <div className="bens-container">
        <h2 className="section-11-title">Engagement Ben S. Digital Change</h2>
        <div className="section-11-box">
          <div className="box-col">
            <img
              src="/img/com-03.webp"
              alt="Sécurité"
              className="box-img"
              loading="lazy"
              width={129}
              height={166}
            />
            <h3 className="box-title">Sécurité</h3>
            <p className="box-desc">
              Notre société est membre de l’ARIF, organisme d’autorégulation
              agréé par la FINMA. Procédez à vos opérations en toute sécurité.
              Notre service de change est contrôlé afin de répondre aux
              directives de la confédération helvétique
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-02.webp"
              alt="Proximité"
              className="box-img"
              loading="lazy"
              width={172}
              height={187}
            />
            <h3 className="box-title">Proximité</h3>
            <p className="box-desc">
              Des bureaux de change physiques à votre disposition pour vous
              accompagner de votre inscription à la réalisation de vos
              opérations de change CHF-EUR
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-05.webp"
              alt="Intuitivité"
              className="box-img"
              loading="lazy"
              width={158}
              height={183}
            />
            <h3 className="box-title">Intuitivité</h3>
            <p className="box-desc">
              Notre plateforme est simple d’utilisation et accessible à tous.
              Avec Notre App, changez vos devises au moment que vous souhaitez,
              depuis l’endroit que vous désirez
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-01.webp"
              alt="Rapidité"
              className="box-img"
              loading="lazy"
              width={169}
              height={175}
            />
            <h3 className="box-title">Rapidité</h3>
            <p className="box-desc">
              Vous recevrez vos fonds convertis en EUR ou en CHF généralement en
              24 h (jour ouvré)
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-04.webp"
              alt="Économie"
              className="box-img"
              loading="lazy"
              width={153}
              height={161}
            />
            <h3 className="box-title">Économie</h3>
            <p className="box-desc">
              Grâce à un taux de change CHF-EUR plus avantageux, et aucuns frais
              cachés, vous économisez sur chaque opération de change
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
