import React, { useContext } from "react";
import { Context } from "../../context";
import TrustpilotCarouselFR from "./fr";
import TrustpilotCarouselDE from "./de";
import TrustpilotCarouselIT from "./it";
import TrustpilotCarouselEN from "./en";

export default function TrustpilotCarousel() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <TrustpilotCarouselDE />;
    case "it":
      return <TrustpilotCarouselIT />;
    case "en":
      return <TrustpilotCarouselEN />;
    default:
      return <TrustpilotCarouselFR />;
  }
}
