import React, { useContext } from "react";
import { Context } from "./../../context";
import OfferFR from "./fr";
import OfferDE from "./de";
import OfferIT from "./it";
import OfferEN from "./en";

export default function Offer() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <OfferDE />;
    case "it":
      return <OfferIT />;
    case "en":
      return <OfferEN />;
    default:
      return <OfferFR />;
  }
}
