import React from "react";
import "./../../styles/components/Commitment.css";

export default function CommitmentIT() {
  return (
    <div className="commitment">
      <div className="bens-container">
        <h2 className="section-11-title">Engagement Ben S. Digital Change</h2>
        <div className="section-11-box">
          <div className="box-col">
            <img
              src="/img/com-03.webp"
              alt="Sicurezza"
              className="box-img"
              loading="lazy"
              width={129}
              height={166}
            />
            <h3 className="box-title">Sicurezza</h3>
            <p className="box-desc">
              La nostra azienda è membro dell'ARIF, un'organizzazione di
              autoregolamentazione approvato dalla FINMA. Svolgi le tue
              operazioni in totale sicurezza. Il nostro servizio di cambio è
              monitorato per soddisfare le direttive della Confederazione
              Svizzera
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-02.webp"
              alt="Prossimità"
              className="box-img"
              loading="lazy"
              width={172}
              height={187}
            />
            <h3 className="box-title">Prossimità</h3>
            <p className="box-desc">
              Uffici di scambio fisici a tua disposizione per te accompagnarti
              dalla tua registrazione al completamento del tuo Operazioni di
              cambio CHF-EUR
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-05.webp"
              alt="Intuitività"
              className="box-img"
              loading="lazy"
              width={158}
              height={183}
            />
            <h3 className="box-title">Intuitività</h3>
            <p className="box-desc">
              La nostra piattaforma è facile da usare e accessibile a tutti. Con
              la nostra app, cambia le tue valute quando vuoi, dal posto che
              vuoi
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-01.webp"
              alt="Velocità"
              className="box-img"
              loading="lazy"
              width={169}
              height={175}
            />
            <h3 className="box-title">Velocità</h3>
            <p className="box-desc">
              Riceverai i tuoi fondi convertiti in EUR o CHF generalmente in 24
              ore (giorno lavorativo)
            </p>
          </div>
          <div className="box-col">
            <img
              src="/img/com-04.webp"
              alt="Economia"
              className="box-img"
              loading="lazy"
              width={153}
              height={161}
            />
            <h3 className="box-title">Economia</h3>
            <p className="box-desc">
              Grazie a un tasso di cambio CHF-EUR più vantaggioso e senza
              commissioni nascosto, risparmi su ogni transazione di scambio
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
