import React, { useContext } from "react";
import { Context } from "../../context";
import HeaderFR from "./fr";
import HeaderDE from "./de";
import HeaderIT from "./it";
import HeaderEN from "./en";

export default function Header() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <HeaderDE />;
    case "it":
      return <HeaderIT />;
    case "en":
      return <HeaderEN />;
    default:
      return <HeaderFR />;
  }
}
