import React from "react";

export default function TrustpilotWidgetDE() {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget section-06"
      data-locale="de"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="624471da9077911b7629b8db"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://de.trustpilot.com/review/bens-digital-change.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
}
