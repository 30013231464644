import React, { useContext } from "react";
import { Context } from "../../context";
import SolutionsFR from "./fr";
import SolutionsDE from "./de";
import SolutionsIT from "./it";
import SolutionsEN from "./en";

export default function Solutions() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <SolutionsDE />;
    case "it":
      return <SolutionsIT />;
    case "en":
      return <SolutionsEN />;
    default:
      return <SolutionsFR />;
  }
}
